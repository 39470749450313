/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import "moment/locale/hu.js"

moment.locale('hu');
